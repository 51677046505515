<template>
<!--  <button @click="clearSession()">RESET</button>-->
  <div v-if="step == 0">
    Пожалуйста используйте браузер сафари.
    <br>
    Для лучшего использования:
    <br>
    1. Нажать на кнопку "Поделиться" в Safari (квадрат с стрелкой вверх, расположеннный внизу экрана).
    <br>
    2. Прокрутить список опций и выбрать "На экран «Домой»".
    <br>
    3. Нажать "Добавить" в верхнем правом углу экрана.
    <br>
    <button class="button" @click="storeInSession(1)">Начать</button>
  </div>
  <div v-else-if="step == 1">
    <img style="width: 100%;" alt="Vue logo" src="../assets/povestka.png">
    <button class="button" @click="storeInSession(2)">ЗАБИТЬ ХЕР</button>
  </div>
  <div v-if="step == 2">
    <img style="width: 70%; padding-top: 40px;" alt="Vue logo" src="../assets/adam1.png">
    <img style="width: 90%;" alt="Vue logo" src="../assets/oblako.png">
    <div v-if="secondVar.text5">
       <span class="step-2-text">
        {{ secondVar.text5 }}
      </span>
      <button class="button2"
              @click="storeInSession(3)">
        Выпить и продолжить
      </button>
    </div>
    <div v-else-if="secondVar.text4">
       <span class="step-2-text">
        {{ secondVar.text4 }}
      </span>
      <button class="button2"
              @click="secondVar.text5 = 'Разливай его свои друзьям и начнем. Не забывайте делать фотки, чтобы вспомнить на утро все.'">
        Продолжить
      </button>
    </div>
    <div v-else-if="secondVar.text3">
       <span class="step-2-text">
        {{ secondVar.text3 }}
      </span>
      <button class="button2"
              @click="secondVar.text4 = 'Я оставил тебе в пакете немного горючего, вот и пришло его время =) Я бы начал с чего-то по слабее.'">
        Продолжить
      </button>
    </div>
    <div v-else-if="secondVar.text2">
       <span class="step-2-text">
        {{ secondVar.text2 }}
      </span>
      <button class="button2"
              @click="secondVar.text3 = 'Но о них чуть позже. Для начала давай немного разогреемся.'">
        Продолжить
      </button>
    </div>
    <div v-else-if="secondVar.text1">
       <span class="step-2-text">
        {{ secondVar.text1 }}
      </span>
      <button class="button2"
              @click="secondVar.text2 = 'Что разнообразить твой праздник, я подготовил тебе несколько заданий.'">
        Продолжить
      </button>
    </div>
    <div v-else>
      <span class="step-2-text">
      Хелоу амигос! Слышал у тебя сегодня праздник ?
    </span>
      <button class="button2" @click="second1(1)">Да</button>
      <button class="button3" @click="second1(2)">Нет</button>
    </div>
  </div>
  <div v-if="step == 3">
    <img style="width: 100%; padding-top: 0px; position: relative; right: 0px;" alt="Vue logo" src="../assets/adam2.png">
    <img style="width: 90%; position: relative; top: -90px;"  alt="Vue logo" src="../assets/oblako.png">
    <div v-if="thirdVar.text3">
       <span class="step-2-text">
        {{ thirdVar.text3 }}
      </span>
      <button class="button2" style="position: relative; top: -140px;" @click="storeInSession(4)">Дальше</button>
    </div>
    <div v-else-if="thirdVar.text2">
       <span class="step-2-text">
        {{ thirdVar.text2 }}
      </span>
      <div style="display: grid; position: relative; top: -150px;">
        <div @click="thirdVar.text3 = 'Слегка забывчивый бабаеб. Вот это поворот, давай дальше!'">
          <input type="radio">Слегка забывчивый...
        </div>
        <div @click="thirdVar.text3 = 'Ветренный разрыватель пилоток. Вот тут удивил, давай дальше!'">
          <input type="radio">Ветренный...
        </div>
        <div @click="thirdVar.text3 = 'Отвественный кунимен. Хах, бывает, давай дальше!'">
          <input type="radio">Отвественный...
        </div>
        <div @click="thirdVar.text3 = 'На все готов ради клитера. Так и думал, давай дальше!'">
          <input type="radio">На все готов...
        </div>
      </div>
    </div>
    <div v-else-if="thirdVar.text1">
       <span class="step-2-text">
        {{ thirdVar.text1 }}
      </span>
      <div style="display: grid; position: relative; top: -150px;">
        <div @click="thirdVar.text2 = 'Лучший аудист района. А мне больше ланос нравится.'">
          <input type="radio">Лучший...
        </div>
        <div @click="thirdVar.text2 = 'Худший продавец атб, никогда не бывает на работе. Я кстати в сильпо работал раньше.'">
          <input type="radio">Худший...
        </div>
        <div @click="thirdVar.text2 = 'Хороший поедатель шаурмы. Покажешь точку мне, я тоже хочу.'">
          <input type="radio">Хороший...
        </div>
        <div @click="thirdVar.text2 = 'Плохой барабанщик, еще и с запахом. Ну а что, все мы люди!!!'">
          <input type="radio">Плохой...
        </div>
      </div>
    </div>
    <div v-else>
      <span class="step-2-text">
      Твое первое задание. Как ты думаешь, что о тебе думают люди. Выбери один вариант.
    </span>
      <div style="display: grid; position: relative; top: -150px;">
        <div @click="thirdVar.text1 = 'Всегда поможет выпить пива. Буду тебя звать, давай дальше.'">
          <input type="radio">Всегда поможет...
        </div>
        <div @click="thirdVar.text1 = 'Лучше не просить, а то потом не выгонешь. Люблю настойчивых. Давай следующий.'">
          <input type="radio">Лучше не просить...
        </div>
        <div @click="thirdVar.text1 = 'Может все сожрать. Хм, пожалуй в следующий раз у тебя. Идем дальше.'">
          <input type="radio">Может все...
        </div>
        <div @click="thirdVar.text1 = 'Лучше без него не пить, а то припомнит. Хах, такое не прощаем, что дальше ?'">
          <input type="radio">Лучше без него...
        </div>
      </div>
    </div>
  </div>
  <div v-if="step == 4">
    <img style="width: 100%; padding-top: 0px; position: relative; right: 0px;" alt="Vue logo" src="../assets/adam3.png">
    <img style="width: 90%; position: relative; top: -90px;"  alt="Vue logo" src="../assets/oblako.png">
    <div v-if="fourthVar.text4">
      <span class="step-2-text">
          {{fourthVar.text4}}
      </span>
      <a style="position: relative; top: -80px; left: 120px;" href="https://osminog2.eat-me.online/ru/category/kokteili-cocktails" target="_blank">Meню</a>
      <button class="button2" style="position: relative; top: -140px;" @click="storeInSession(5)">Выпить и продолжить</button>
    </div>
    <div v-else-if="fourthVar.text3">
      <span class="step-2-text">
          {{fourthVar.text3}}
      </span>
      <button class="button2" style="position: relative; top: -140px;" @click="fourthVar.text4 = 'Рекомендую попробовать один из них. Незабывай сделать фото.'">Продолжить</button>
    </div>
    <div v-else-if="fourthVar.text2">
      <span class="step-2-text">
          {{fourthVar.text2}}
      </span>
      <button class="button2" style="position: relative; top: -140px;" @click="fourthVar.text3 = 'Он славится своей морской тематикой, а так же интересной подачей коктейлей.'">Продолжить</button>
    </div>
    <div v-else-if="fourthVar.text1">
      <span class="step-2-text">
          {{fourthVar.text1}}
      </span>
      <button class="button2" style="position: relative; top: -140px;" @click="fourthVar.text2 = 'Это экзотический ресторан Osminog.'">На месте</button>
    </div>
    <div v-else>
        <span class="step-2-text">
          Я узнал о тебе много нового. Нам пора отправится дальше.
      </span>
      <button class="button2" style="position: relative; top: -140px;" @click="fourthVar.text1 = 'Твоя следущая остановка по адресу Львовская площадь --- 4B. Дай знать, как будешь там.'">Дальше</button>
    </div>
  </div>
  <div v-if="step == 5">
    <img style="width: 100%; padding-top: 0px; position: relative; right: 0px;" alt="Vue logo" src="../assets/adam4.png">
    <img style="width: 90%; position: relative; top: -90px;"  alt="Vue logo" src="../assets/oblako.png">
    <div v-if="fifthVar.text6">
      <span class="step-2-text">
          {{fifthVar.text6}}
      </span>
      <a style="position: relative; top: -80px; left: 120px;" href="https://gamondi.choiceqr.com/section:menyu/podorozh-gamondi" target="_blank">Meню</a>
      <button class="button2" style="position: relative; top: -140px;" @click="storeInSession(6)">Выпить и продолжить</button>
    </div>
    <div v-else-if="fifthVar.text5">
      <span class="step-2-text">
          {{fifthVar.text5}}
      </span>
      <button class="button2" style="position: relative; top: -140px;" @click="fifthVar.text6 = 'Незабывай сделать фото.'">Продолжить</button>
    </div>
    <div v-else-if="fifthVar.text4">
      <span class="step-2-text">
          {{fifthVar.text4}}
      </span>
      <button class="button2" style="position: relative; top: -140px;" @click="fifthVar.text5 = 'Я бы рекомендовал попробовать вам их карту негрони с разных стран.'">Продолжить</button>
    </div>
    <div v-else-if="fifthVar.text3">
      <span class="step-2-text">
          {{fifthVar.text3}}
      </span>
      <button class="button2" style="position: relative; top: -140px;" @click="fifthVar.text4 = 'Это суперовский бар называется Gamondi Bar'">На месте</button>
    </div>
    <div v-else-if="fifthVar.text2">
       <span class="step-2-text">
        {{ fifthVar.text2 }}
      </span>
      <div style="display: grid; position: relative; top: -150px;">
        <div @click="fifthVar.text3 = '24? ВАУУУУ!!!! Твоя следущая остановка 50.45220591723249, 30.521442099381346'">
          <input type="radio">24
        </div>
        <div @click="fifthVar.text3 = '25? ВАУУУУ!!!! Твоя следущая остановка 50.45220591723249, 30.521442099381346'">
          <input type="radio">25
        </div>
        <div @click="fifthVar.text3 = '26? ВАУУУУ!!!! Твоя следущая остановка 50.45220591723249, 30.521442099381346'">
          <input type="radio">26
        </div>
        <div @click="fifthVar.text3 = '27? ВАУУУУ!!!! Твоя следущая остановка 50.45220591723249, 30.521442099381346'">
          <input type="radio">27
        </div>
      </div>
    </div>
    <div v-else-if="fifthVar.text1">
       <span class="step-2-text">
        {{ fifthVar.text1 }}
      </span>
      <div style="display: grid; position: relative; top: -150px;">
        <div>
          <input disabled type="radio">03.01
        </div>
        <div @click="fifthVar.text2 = 'Сколько сантиметров у тебя член ?'">
          <input type="radio">04.01
        </div>
        <div>
          <input disabled type="radio">05.01
        </div>
        <div>
          <input disabled type="radio">06.01
        </div>
      </div>
    </div>
    <div v-else>
      <span class="step-2-text">
      Теперь проверим, как хорошо ты помнишь числа. Когда др у твоей будущей тещи ?
    </span>
      <div style="display: grid; position: relative; top: -150px;">
        <div>
          <input disabled type="radio">21.04
        </div>
        <div>
          <input disabled type="radio">21.03
        </div>
        <div @click="fifthVar.text1 = 'Когда др твоей бабушки ?'">
          <input type="radio">23.04
        </div>
        <div>
          <input disabled type="radio">23.03
        </div>
      </div>
    </div>
  </div>
  <div v-if="step == 6">
    <img style="width: 70%; padding-top: 40px;" alt="Vue logo" src="../assets/adam1.png">
    <img v-if="!sixVar.text2" style="width: 90%;" alt="Vue logo" src="../assets/oblako.png">
    <div v-if="sixVar.text8">
      <span class="step-2-text">
          {{sixVar.text8}}
      </span>
      <button class="button2" style="position: relative;" @click="storeInSession(7)">Выпить и продолжить</button>
    </div>
    <div v-else-if="sixVar.text7">
      <span class="step-2-text">
          {{sixVar.text7}}
      </span>
      <button class="button2" style="position: relative;" @click="sixVar.text8 = 'Незабывай сделать фото.'">Продолжить</button>
    </div>
    <div v-else-if="sixVar.text6">
      <span class="step-2-text">
          {{sixVar.text6}}
      </span>
      <button class="button2" style="position: relative;" @click="sixVar.text7 = 'Я бы рекомендовал вкуснейшую крем-соду, но выбирай что хочешь=)'">Продолжить</button>
    </div>
    <div v-else-if="sixVar.text5">
       <span class="step-2-text">
        {{ sixVar.text5 }}
         <img style="width: 56%; padding-top: 0px;" alt="Vue logo" src="../assets/bar.png">
      </span>
      <button class="button2"
              @click="sixVar.text6 = 'Этот бар не для всех, а только для тебя. Он называется Бар твоих друзей.'">
        На месте
      </button>
    </div>
    <div v-else-if="sixVar.text4">
       <span class="step-2-text">
        {{ sixVar.text4 }}
      </span>
      <button class="button2"
              @click="sixVar.text5 = 'Тебе сюда'">
        Продолжить
      </button>
    </div>
    <div v-else-if="sixVar.text2">
       <span class="step-2-text">
         <span style="color: red;">{{sixVar.text3}}</span> <br>
        1. Stas <br>
        2. burps <br>
        3. butt <br>
        4. Yura <br>
        5. I <br>
        6. beautiful <br>
        7. love <br>
        8. smile <br>
        9. Vova <br>
        a. her <br>
        b. for <br>
        c. Sasha <br>
        d. fart <br>
      </span>
      <input type="text" v-model="input6">
      <br>
      <button class="button2"
              @click="six()">
        Продолжить
      </button>
    </div>
    <div v-else-if="sixVar.text1">
       <span class="step-2-text">
        {{ sixVar.text1 }}
      </span>
      <button class="button2"
              @click="sixVar.text2 = 123">
        Начать
      </button>
    </div>
    <div v-else>
      <span class="step-2-text">
      Я надеюсь ты готов двигаться дальше. Твое новое задание.
    </span>
      <button class="button2"
              @click="sixVar.text1 = 'Тебе нужно получить код составив правильное предложение.'">
        Продолжить
      </button>
    </div>
  </div>
  <div v-if="step == 7">
    <img style="width: 100%; padding-top: 0px; position: relative; right: 0px;" alt="Vue logo" src="../assets/adam2.png">
    <img style="width: 90%; position: relative; top: -90px;"  alt="Vue logo" src="../assets/oblako.png">
    <div v-if="sevenVar.text3">
       <span class="step-2-text">
        {{ sevenVar.text3 }}
      </span>
      <button class="button2" style="position: relative; top: -140px;" @click="storeInSession(8)">Выыпить и дальше.</button>
    </div>
    <div v-else-if="sevenVar.text2">
       <span class="step-2-text">
        {{ sevenVar.text2 }}
      </span>
      <button class="button2" style="position: relative; top: -140px;" @click="sevenVar.text3 = 'Не забывай сделать фото.'">Дальше</button>
    </div>
    <div v-else-if="sevenVar.text1">
       <span class="step-2-text">
        {{ sevenVar.text1 }}
      </span>
      <button class="button2" style="position: relative; top: -140px;" @click="sevenVar.text2 = 'Мне кажется пришло время использовать вторую часть содержимого пакета.'">На месте</button>
    </div>
    <div v-else>
      <span class="step-2-text">
      Я надеюсь ты не скучаешь. Я предлагаю тебе немного пройтись и подышать воздухом.
    </span>
      <button class="button2" style="position: relative; top: -140px;" @click="sevenVar.text1 = 'Может быть к мосту Кличка ?'">Дальше</button>
    </div>
  </div>
  <div v-if="step == 8">
    <img style="width: 70%; padding-top: 40px;" alt="Vue logo" src="../assets/adam1.png">
    <img style="width: 90%;" alt="Vue logo" src="../assets/oblako.png">
    <div v-if="eightVar.text7">
       <span class="step-2-text">
            {{eightVar.text7}}
         <br>
         Незабудь сделать фото.
                  <br>
          Цем!
      </span>
    </div>
    <div v-else-if="eightVar.text6">
       <span class="step-2-text">
            {{eightVar.text6}}
      </span>
      <button class="button2"
              @click="eightVar.text7 = 'Я желаю тебе крепкой семьи и вкусного пива.'">
        Продолжить
      </button>
    </div>
    <div v-else-if="eightVar.text5">
       <span class="step-2-text">
            <img style="width: 90%;" alt="Vue logo" src="../assets/pivo.jpeg">
      </span>
      <button class="button2"
              @click="eightVar.text6 = 'Надеюсь я угодал и ты хорошо проведешь время. '">
        На месте
      </button>
      </div>
    <div v-else-if="eightVar.text4">
       <span class="step-2-text">
        {{ eightVar.text4 }}
      </span>
      <button class="button2"
              @click="eightVar.text5 = true">
        Открыть
      </button>
      </div>
    <div v-else-if="eightVar.text3">
       <span class="step-2-text">
        {{ eightVar.text3 }}
      </span>
      <button class="button2"
              @click="eightVar.text4 = 'Открой его, когда приедешь туда, чтобы узнать угадал я или нет =)'">
        Продолжить
      </button>
      </div>
    <div v-else-if="eightVar.text2">
       <span class="step-2-text">
        {{ eightVar.text2 }}
      </span>
      <button class="button2"
              @click="eightVar.text3 = 'За одной из кнопок будет фото твоего любимого места.'">
        Продолжить
      </button>
      </div>
    <div v-else-if="eightVar.text1">
       <span class="step-2-text">
        {{ eightVar.text1 }}
      </span>
      <button class="button2"
              @click="eightVar.text2 = 'Давай сыграем с тобой в последнюю игру.'">
        Продолжить
      </button>
    </div>
    <div v-else>
      <span class="step-2-text">
      Мне кажется мы достаточно находились и пора бы где-то упасть
    </span>
      <button class="button2"
              @click="eightVar.text1 = 'Я точно знаю какое место ты очень любишь, так что скорее вызывай туда такси.'">
        Продолжить
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue'
import { reactive } from 'vue'

export default {
  name: 'HelloWorld',
  setup () {
    const input6 = ref()
    const step = ref(0)
    const secondVar = reactive({
      text1: null,
      text2: null,
      text3: null,
      text4: null,
      text5: null,
    })
    const thirdVar = reactive({
      text1: null,
      text2: null,
      text3: null,
      text4: null,
      text5: null,
      text6: null,
    })
    const fourthVar = reactive({
      text1: null,
      text2: null,
      text3: null,
      text4: null,
      text5: null,
    })
    const fifthVar = reactive({
      text1: null,
      text2: null,
      text3: null,
      text4: null,
      text5: null,
      text6: null,
    })
    const sixVar = reactive({
      text1: null,
      text2: null,
      text3: null,
      text4: null,
      text5: null,
      text6: null,
      text7: null,
      text8: null,
    })
    const sevenVar = reactive({
      text1: null,
      text2: null,
      text3: null,
      text4: null,
      text5: null,
      text6: null,
      text7: null,
      text8: null,
    })
    const eightVar = reactive({
      text1: null,
      text2: null,
      text3: null,
      text4: null,
      text5: null,
      text6: null,
      text7: null,
      text8: null,
    })

    return {
      input6,
      step,
      eightVar,
      sevenVar,
      sixVar,
      fifthVar,
      fourthVar,
      thirdVar,
      secondVar
    }
  },
  computed: {},
  methods: {
    six() {
      if(this.input6 == '57cba68') {this.sixVar.text2 = null; this.sixVar.text4 = 'Ой какие милости. Ну ладно.'} else {this.sixVar.text3 = {0: 'Попробуй еще!', 1: 'Уверен?', 2: 'Что-то не то.', 3: 'Ахахаха', 4: 'Ну ты даешь.'}[Math.floor(Math.random() * 5)]}
    },
    second1 (type) {
      if (type == 1) {
        this.secondVar.text1 = 'Супер, тогда давай проведем его вместе!'
      } else {
        this.secondVar.text1 = 'Тогда я тебе его устрою!'
      }
    },
    storeInSession (step) {
      sessionStorage.setItem('step', step)
      this.getStep();
    },
    getFromSession () {
      return sessionStorage.getItem('step') ?? 0
    },
    clearSession () {
      sessionStorage.removeItem('step')
    },
    getStep () {
      this.step = this.getFromSession() ?? 0
    }
  },
  created () {
    this.getStep()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
  background-color: rgba(0, 128, 0, 1); /* Green with 50% opacity */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button2 {
  background-color: rgba(0, 128, 0, 1); /* Green with 50% opacity */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button3 {
  background-color: rgba(128, 0, 0, 1); /* Green with 50% opacity */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.step-2-text {
  position: absolute;
  left: 28%;
  top: 54%;
  width: 189px;
}
</style>
